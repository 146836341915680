import React from "react";
import Layout from "../layouts/Layout";

import { ContactDetails, Form, GoogleMap } from "../components/Contact";
import { Container, Grid } from "@material-ui/core";

const Contact = () => (
  <Layout title="Contact" whatsapp={false}>
    <GoogleMap />
    <Container maxWidth="md">
      <Grid container>
        <ContactDetails />
        <Form />
      </Grid>
    </Container>
  </Layout>
);

export default Contact;
